<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a @click="back" class="btn p-0 mr-md-4 text-light h5 mb-0">
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate"
              >Pesanan Pembeli</span
            >
          </span>
          <div class="text-nowrap order-md-last">
            <a
              id="shareBtn"
              class="btn px-0 d-inline-block mr-3"
              @click="shareData"
            >
              <i class="fa fa-share-alt text-light"></i>
            </a>
            <router-link
              :to="'/jual/pesanan/edit/' + state.id"
              class="btn px-0 d-inline-block"
            >
              <i class="fas fa-pencil-alt text-light"></i>
            </router-link>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header" v-if="state.finish">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="'background-image: url(' + state.image + ');'"
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">
                {{ state.data.pelanggan.prefix }}
                {{ state.data.pelanggan.name }}
              </p>
            </div>
          </div>
          <div id="details-accordion">
            <div class="w-100 text-center">
              <a
                class="collapsed"
                data-toggle="collapse"
                data-target="#collapse-details"
                aria-expanded="true"
                aria-controls="collapse-details"
              >
                <i class="fa" aria-hidden="true"></i>
              </a>
            </div>
            <div
              id="collapse-details"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#details-accordion"
            >
              <div class="row mt-3">
                <div class="col-8">
                  <p class="mb-1 small text-muted">Customer Grup</p>
                  <p class="mb-0 text-truncate">
                    {{ state.data.pelanggan.group.name }}
                  </p>
                </div>
                <!-- .col -->
                <div class="col-4 text-right text-md-left">
                  <p class="mb-1 small text-muted">Status</p>
                  <p class="mb-0 text-truncate">
                    <span
                      class="badge align-top"
                      :class="
                        state.data.status === 1
                          ? 'badge-secondary'
                          : 'badge-danger'
                      "
                      >{{ state.data.status === 1 ? "AKTIF" : "INAKTIF" }}</span
                    >
                  </p>
                </div>
                <!-- .col -->
              </div>
              <!-- .row -->

              <div class="row mt-3">
                <div class="col-6">
                  <p class="mb-1 small text-muted">Jumlah Pesanan</p>
                  <p class="mb-0">
                    {{ state.jumlah_pesanan }} pesanan, {{ state.jumlah }} pcs
                  </p>
                </div>
                <!-- .col -->
                <div class="col-6 text-right text-md-left">
                  <p class="mb-1 small text-muted">Harga</p>
                  <p class="mb-0 currency">
                    Rp. {{ changeMoney(state.total) }}
                  </p>
                </div>
                <!-- .col -->
              </div>
              <!-- .row -->

              <div class="row mt-3">
                <div class="col-8">
                  <p class="mb-1 small text-muted">Alamat</p>
                  <p class="mb-0 text-truncate">SG, E1-77 GV</p>
                </div>
                <!-- .col -->
                <div class="col-4 text-right text-md-left">
                  <p class="mb-1 small text-muted">Pengiriman</p>
                  <p class="mb-0 text-truncate">{{ state.data.pengiriman }}</p>
                </div>
                <!-- .col -->
              </div>
              <!-- .row -->
            </div>
          </div>

          <div class="row mt-3 pt-3 border-top">
            <div class="col-3 text-center">
              <p class="mb-1 small">Siap</p>
              <form>
                <div class="custom-control custom-checkbox mt-1 ml-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    value="siap"
                    id="siap"
                    :checked="state.siap"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck1"
                    @click="changeStatus('siap')"
                  ></label>
                </div>
              </form>
            </div>
            <!-- .col -->
            <div class="col-3 text-center">
              <p class="mb-1 small">Terkirim</p>
              <form>
                <div class="custom-control custom-checkbox mt-1 ml-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    value="siap"
                    id="siap"
                    :checked="state.terkirim"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck2"
                    @click="changeStatus('terkirim')"
                  ></label>
                </div>
              </form>
            </div>
            <!-- .col -->
            <div class="col-3 text-center">
              <p class="mb-1 small">Invoice</p>
              <form>
                <div class="custom-control custom-checkbox mt-1 ml-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :checked="state.invoice"
                    :disabled="true"
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck3"
                  ></label>
                </div>
              </form>
            </div>
            <!-- .col -->
            <div class="col-3 text-center">
              <p class="mb-1 small">Pembayaran</p>
              <form>
                <div class="custom-control custom-checkbox mt-1 ml-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :checked="state.pembayaran"
                    disabled
                  />
                  <label
                    class="custom-control-label"
                    for="customCheck4"
                  ></label>
                </div>
              </form>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mb-3">
            <div class="col-12 px-0">
              <ul class="list-group rounded-0 mt-3">
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <router-link
                    :to="'/jual/transaksi/info/' + state.data.transaksi.id"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    >Invoice &amp; Pembayaran</router-link
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <a
                    href=""
                    class="py-2 pl-3 text-uppercase stretched-link"
                    data-toggle="modal"
                    data-target="#deliveryModal"
                    title="Informasi pengiriman..."
                    >Pengiriman</a
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
              </ul>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <form @submit.prevent="searchData">
            <div class="input-group input-group-sm">
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Cari..."
                  v-model="state.search"
                />
                <span class="input-group-append">
                  <button type="submit" class="btn btn-outline-main">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </form>

          <div class="small text-truncate">
            Produk Pesanan {{ state.data.pelanggan.prefix }}
            {{ state.data.pelanggan.name }}:
          </div>

          <div
            id="resultList"
            class="row mt-3 mb-5"
          >
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" v-for="detail in state.detail"
            :key="detail.id">
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="float-left w-25 border-right">
                    <div
                      class="cropped"
                      :style="
                        'background-image: url(' +
                        state.url +
                        detail.produk.photo +
                        ');'
                      "
                    ></div>
                  </div>
                  <div class="float-right w-75 p-2 pl-3">
                    <router-link
                      :to="'/jual/produk/info/' + detail.produk.id"
                      class="
                        card-title
                        text-main
                        font-weight-bold
                        d-block
                        mb-1
                        mt-1
                        card-link
                        stretched-link
                        text-truncate
                      "
                      >{{ detail.produk.name }}</router-link
                    >
                    <p class="card-text mb-1 text-truncate">
                      {{ detail.jumlah }} pcs
                    </p>
                    <p class="card-text mb-0 text-truncate">
                      <span class="currency"
                        >Rp. {{ changeMoney(detail.total) }}</span
                      >
                    </p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <router-link
            :to="'/jual/pesanan/edit/' + state.id"
            id="btn-float"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
          >
            <div class="d-flex justify-content-center mt-3">
              <i class="fas fa-plus" style="font-size: 25px"></i>
            </div>
          </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
    <div class="modal fade" id="deliveryModal" v-if="state.finish">
      <div
        class="
          modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable
        "
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Pengiriman</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <Form
            action=""
            class="needs-validation"
            @submit="updatePengiriman"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="modal-body">
              <div class="form-group">
                <label for="status"
                  >Status Pengiriman:<span class="text-danger ml-1"
                    >*</span
                  ></label
                >
                <Field
                  name="status"
                  as="select"
                  class="form-control"
                  :class="{ 'is-invalid': errors.kirim }"
                  v-model="state.form.kirim"
                >
                  <option :value="1">Terkirim</option>
                  <option :value="0">Belum</option>
                </Field>
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">{{ errors.kirim }}</div>
              </div>

              <div class="form-group">
                <label for="sel3"
                  >Pengiriman:<span class="text-danger ml-1">*</span></label
                >
                <Field
                  name="pengiriman"
                  as="select"
                  class="form-control"
                  :class="{ 'is-invalid': errors.pengiriman }"
                  v-model="state.form.pengiriman"
                >
                  <option value="">Pilih pengiriman...</option>
                  <option value="Antar Langsung">Antar Langsung</option>
                  <option value="Diambil">Diambil</option>
                  <option value="Reseller">Reseller</option>
                  <option value="Ojek Instan">Ojek Instan</option>
                  <option value="Hari Yang Sama">Hari Yang Sama</option>
                  <option value="Paket">Paket</option>
                </Field>
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">
                  {{ errors.pengiriman }}
                </div>
              </div>
              <div class="form-group">
                <label for="modal">Ongkir: </label>
                <currency-input
                  class="form-control currency"
                  :class="{ 'is-invalid': errors.ongkir }"
                  v-model="state.form.ongkir"
                  :options="{ currency: 'IDR' }"
                  name="ongkir"
                />
                <Field
                  type="hidden"
                  v-model="state.form.ongkir"
                  name="ongkir"
                />
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">{{ errors.ongkir }}</div>
              </div>
              <div class="form-group">
                <label for="alamat" class=""
                  >Alamat: <span class="text-danger">*</span></label
                >
                <textarea
                  class="form-control form-control-sm"
                  rows="2"
                  id="alamat"
                  name="alamat"
                  placeholder="Masukkan alamat pengiriman..."
                  disabled
                ></textarea>
                <div class="valid-feedback">Valid.</div>
                <div class="invalid-feedback">Wajib diisi.</div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-main"
                :disabled="state.loading"
              >
                <i class="fa fa-floppy-o mr-1"></i>Simpan
              </button>
              <button type="button" class="btn btn-light" data-dismiss="modal">
                <i class="fa fa-back mr-1"></i>Kembali
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <!-- #deliveryModal -->
  </div>
</template>
<script>
import { reactive, onMounted, watchEffect, ref } from "vue";
import { useRoute } from "vue-router";
import imageBaner from "../../../assets/img/ca1.jpg";
import axios from "axios";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import Share from "../../../components/social_media/Share";
import CurrencyInput from "../../../helpers/CurrencyInput.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import $ from 'jquery';

export default {
  setup() {
    const $swal = useVueSweetAlert2();
    const Toast = $swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
    const route = useRoute();
    const state = reactive({
      id: "0",
      image: imageBaner,
      po_id: "0",
      data: {},
      finish: false,
      url: process.env.VUE_APP_API_URL,
      jumlah_pesanan: 0,
      jumlah: 0,
      total: 0,
      search: "",
      detail: {},
      originalDetail: {},
      siap: false,
      terkirim: false,
      pembayaran: false,
      isShow: true,
      form: {
        ongkir: 0,
        pengiriman: "",
        kirim: 0,
      },
      loading: false,
    });
    const ket = ref([]);
    const loadData = async () => {
      state.finish = false;
      await axios.get("/api/seller/getCustomer/" + state.id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          state.po_id = res.data.data.po_id;
          state.jumlah_pesanan = res.data.data.detail.length;
          state.detail = res.data.data.detail;
          state.originalDetail = res.data.data.detail;
          state.detail.map((item) => {
            state.jumlah += Number(item.jumlah);
            state.total += Number(item.total);
          });
          //set status
          state.siap = state.data.siap;
          state.terkirim = state.data.terkirim;
          state.invoice = state.data.invoice;
          state.pembayaran = state.data.pembayaran;
          //form pengiriman
          state.form.kirim = state.data.terkirim;
          state.form.pengiriman = state.data.pengiriman;
          state.form.ongkir = Number(res.data.data.ongkir);
        }
        state.finish = true;
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const changeMoney = (dt) => {
      let cur = Math.ceil(Number(dt));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];
      return ret;
    };
    watchEffect(
      () => ket.value,
      (newVal) => {
        console.log(newVal);
      }
    );

    const searchData = () => {
      let result = [];
      const data = state.originalDetail;
      Object.values(data).find((dt, index) => {
        if (dt.produk.name.match(state.search)) {
          result.push(data[index]);
        }
      });
      state.detail = result;
    };
    const changeStatus = (dt) => {
      $swal
        .fire({
          text: "Apakah Anda Yakin?",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let val = true;
            if (dt == "siap") {
              val = !state.siap;
            } else if (dt == "terkirim") {
              val = !state.terkirim;
            }
            let form = {
              status: dt,
              value: val,
            };
            let title = "Status reset!";
            if (val === true) {
              if (dt === "siap") {
                title = "Pesanan ini telah siap.";
              } else {
                title = "Pesanan ini telah dikirim.";
              }
            }
            axios
              .post("/api/seller/pesanan/setStatus/" + state.id, form)
              .then((res) => {
                if (res.data.success) {
                  loadData();
                  Toast.fire({
                    icon: "success",
                    title: title,
                  });
                }
              });
          }
        });
    };
    const shareData = async () => {
      //share
      const prefix =
        state.data.pelanggan.prefix === null ? "" : state.data.pelanggan.prefix;
      const name = prefix + " " + state.data.pelanggan.name;
      let desc = "";
      await state.detail.map((dt) => {
        console.log(dt);
        desc +=
          "*" +
          dt.produk.name +
          "-" +
          dt.jumlah +
          " pcs \n Rp. " +
          changeMoney(Number(dt.harga) + Number(dt.margin));
      });
      let dt = {
        title: "Pesanan " + name + " :",
        description: desc,
        url: "",
      };
      Share.run("whatsapp", dt);
    };
    const back = () => {
      window.history.back();
    };
    //start validate
    const schema = Yup.object().shape({
      pengiriman: Yup.string().required("Pilih Salah satu"),
    });
    const updatePengiriman = () => {
      state.loading = true;
      axios
        .post("/api/seller/pesanan/updatePengiriman/" + state.id, state.form)
        .then(
          (res) => {
            if (res.data.success) {
              loadData();
              $('#deliveryModal').modal('toggle');

              Toast.fire({
                icon: "success",
                title: "Update Pengiriman Berhasil!",
              });
            }
            state.loading = false;
          },
          () => {
            state.loading = false;
          }
        );
    };
    return {
      state,
      changeMoney,
      changeStatus,
      searchData,
      shareData,
      back,
      schema,
      updatePengiriman,
    };
  },
  components: {
    CurrencyInput,
    Form,
    Field,
  },
};
</script>